.table-fixed {
    table-layout: fixed;
}

.wrapper {
    max-height: calc(100vh - 200px);
    overflow: scroll;
}

.table {
    border-collapse: collapse;
    border-spacing: 0;



    tr:hover td {
        @apply .bg-grey-lighter;
    }

    th {
        @apply .bg-grey-light .font-bold .text-xs .text-grey-darker .uppercase .border-b-2 .border-grey .py-3 .px-3 .tracking-wide .align-middle;
    }

    td {
        @apply .font-light .text-grey-darkest .border-t .border-b .border-grey .px-3 .align-middle;
        height: 3.8125rem;
    }

    &.tight {
        th,
        td {
            @apply .px-1 .py-1
        }
    }
}
.th-fit,
.td-fit {
    width: 1%;
    white-space: nowrap;
}
