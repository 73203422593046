.tabs-component-tabs {
    margin-bottom: 5px;
}

@media (min-width: 700px) {
    .tabs-component-tabs {
        @apply list-reset;

        align-items: stretch;
        display: flex;
        justify-content: flex-start;
        margin-bottom: -1px;
    }
}

.tabs-component-tab {
    @apply text-grey;
}


.tabs-component-tab:hover {
    color: #666;
}

.tabs-component-tab.is-active {
    color: #000;
}

.tabs-component-tab.is-disabled * {
    color: #cdcdcd;
    cursor: not-allowed !important;
}

@media (min-width: 700px) {
    .tabs-component-tab {
        background-color: #fff;
        border: solid 1px #ddd;
        border-radius: 3px 3px 0 0;
        margin-right: .5em;
        transform: translateY(2px);
        transition: transform .3s ease;
    }

    .tabs-component-tab.is-active {
        border-bottom: solid 1px #fff;
        z-index: 2;
        transform: translateY(0);
    }
}

.tabs-component-tab-a {
    align-items: center;
    color: inherit;
    display: flex;
    padding: .75em .5em;
    text-decoration: none;
    font-size: 13px;
}

.tabs-component-panels {
    @apply p-4;
}

@media (min-width: 700px) {
    .tabs-component-panels {
        @apply relative bg-white border;
    }
}
