.btn {
    @apply font-bold py-2 px-4 rounded no-underline;
}

.btn-sm {
    @apply text-sm font-bold py-1 px-2 rounded no-underline;
}

.btn-orange {
    @apply bg-orange text-white;
}

.btn-orange:hover {
    @apply bg-orange-dark;
}

.btn-orange:disabled {
    @apply bg-orange-lighter;
}

.btn-grey {
    @apply bg-grey text-white;
}

.btn-grey:hover {
    @apply bg-grey-dark;
}

.btn-grey:disabled {
    @apply bg-grey-lighter;
}

.btn-red {
    @apply bg-red text-white;
}

.btn-red:hover {
    @apply bg-red-dark;
}

.btn-red:disabled {
    @apply bg-red-lighter;
}

.btn-white {
    @apply bg-white border;
}

.btn-white:hover {
    @apply bg-grey-lightest;
}

.btn-white:disabled {
    @apply bg-grey-lightest;
}