
.form-row {
    background-image: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPSc4JyBoZWlnaHQ9JzgnPjxjaXJjbGUgY3g9JzEnIGN5PSc0JyByPScxJyBmaWxsPScjZDBkM2Q3JyAvPjwvc3ZnPg==);
    background-repeat: repeat-x;
    background-position: left 8px;
}

.form-control {
    @apply .h-6;
    line-height: 1;
}

.form-input {
    @apply .bg-white .text-sm .border .border-white .px-1 .text-black .rounded;
}

textarea.form-input {
    @apply .py-1;
}

.form-control-focus {
    outline: none;
}

.form-input:focus,
.form-input:active {
    @apply .form-control-focus;
}

.form-input-bordered {
    @apply .bg-white .border .border-grey-dark .px-1 .text-grey-darker .rounded;
}

.form-select {
    @apply .bg-white .border .border-grey-dark .pl-3 .pr-8 .rounded .text-grey-darker;
    appearance: none;
    background-repeat: no-repeat;
    background-size: 15px 15px;
    background-position: center right 0.75rem;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1' stroke='currentColor' style='color:%2335393C;'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M19.5 8.25l-7.5 7.5-7.5-7.5' /%3E%3C/svg%3E%0A");
}

// input.form-input:read-only,
// textarea.form-input:read-only,
.form-input:active:disabled,
.form-input:focus:disabled,
.form-select:active:disabled,
.form-select:focus:disabled {
    box-shadow: none;
    @apply shadow;
}

// input.form-input:read-only,
// textarea.form-input:read-only,
.form-input:disabled,
.form-select:disabled {
    @apply .bg-grey-lighter;
    cursor: not-allowed;
}

.form-select:focus {
    @apply .form-control-focus;
}

.form-combo {
    @apply .bg-white .border .border-grey .rounded .text-grey-darker .overflow-hidden;
}

.form-combo-select {
    @apply .bg-white .bg-transparent .pr-4;
    outline: none;
    background-repeat: no-repeat;
    background-size: 10px 6px;
    background-position: center right 0.75rem;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 6"><path fill="#35393C" fill-rule="nonzero" d="M8.293.293a1 1 0 0 1 1.414 1.414l-4 4a1 1 0 0 1-1.414 0l-4-4A1 1 0 0 1 1.707.293L5 3.586 8.293.293z"/></svg>');
}
