html.modal-body,
body.modal-body {
    overflow: hidden;
    position: relative;
    height: 100%;
}

@media screen and (max-width:768px) {
    .modal {
        max-height: calc(100vh - 100px) !important;
    }
}

.swal2-shown .swal2-container {
    z-index: 4000;
}
