/**
 * This injects Tailwind's base styles, which is a combination of
 * Normalize.css and some additional base styles.
 *
 * You can see the styles here:
 * https://github.com/tailwindcss/tailwindcss/blob/master/css/preflight.css
 *
 * If using `postcss-import`, use this import instead:
 *
 * @import "tailwindcss/preflight";
 */
@tailwind preflight;

/**
 * This injects any component classes registered by plugins.
 *
 * If using `postcss-import`, use this import instead:
 *
 * @import "tailwindcss/components";
 */
@tailwind components;

/**
 * Here you would add any of your custom component classes; stuff that you'd
 * want loaded *before* the utilities so that the utilities could still
 * override them.
 *
 * Example:
 *
 * .btn { ... }
 * .form-input { ... }
 *
 * Or if using a preprocessor or `postcss-import`:
 *
 * @import "components/buttons";
 * @import "components/forms";
 */
@import "components/buttons";
@import "components/table";
@import "components/forms";
@import "components/modal";
@import "components/signature_editor";
@import "components/vue-select.scss";
@import "components/tabs";

.card {
    @apply bg-white shadow rounded;
}

/**
 * This injects all of Tailwind's utility classes, generated based on your
 * config file.
 *
 * If using `postcss-import`, use this import instead:
 *
 * @import "tailwindcss/utilities";
 */
@tailwind utilities;

/**
 * Here you would add any custom utilities you need that don't come out of the
 * box with Tailwind.
 *
 * Example :
 *
 * .bg-pattern-graph-paper { ... }
 * .skew-45 { ... }
 *
 * Or if using a preprocessor or `postcss-import`:
 *
 * @import "utilities/background-patterns";
 * @import "utilities/skew-transforms";
 */

.w-sidebar {
    width: 100%;

    @screen md {
        width: 10rem;
        position: fixed;
        top: 0;
        bottom: 0;

        .js-navigaion {
            max-height: calc(100vh - 95px);
        }
    }

    @screen lg {
        width: 18rem;
    }
}

.sidebar-content {
    width: 100%;
    height: 100vh;
    overflow-y: scroll;

    @screen md {
        max-height: initial;
        overflow-y: initial;
    }
}

.content {
    width: 100%;
    // margin-top: 70px;

    @screen md {
        max-width: calc(100vw - 10rem);
        margin-left: 10rem;
    }

    @screen lg {
        max-width: calc(100vw - 18rem);
        margin-left: 18rem;
    }
}

.h-header {
    height: 48px;

    @screen md {
        height: 95px;
    }
}

.a4-iframe {
    position: relative;
    padding-bottom: calc(100vh - 4rem);
    height: 0;
    overflow: hidden;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

@screen md {
    .md\:pin-b-initial {
        bottom: initial;
    }
}

.animate-spin {
    animation: spin 1s linear infinite!important
}


@keyframes spin {
    to {
        transform: rotate(1turn)
    }
}

@screen md {
    .action-buttons {
        margin-left: 220px;
        margin-bottom: -24px;
        margin-top: 0;
    }
}

@media (max-width: 991px) {
    .menu-container {
        background-color: #fff;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        padding: .5rem;
    }
}

#index #index-table-container {
    height: calc(100vh - 220px);
    width: 100%;
}

#index  #welding-certificates #index-table-container {
    height: calc(100vh - 405px);
}

#relations #index-table-container {
    height: 300px;
    width: 100%;
}

#index #file-system #index-table-container {
    height: calc(100vh - 5d00px);
    width: 100%;
}

@screen lg {
    #index #index-table-container {
        height: calc(100vh - 165px);
    }

    #index  #welding-certificates #index-table-container {
        height: calc(100vh - 245px);
    }

    #index #file-system #index-table-container {
        height: calc(100vh - 200px);
    }
}

.agCheckbox {
    border: 1px solid #000;
    border-radius: 3px;
    width: 16px;
    height: 16px;
    margin-right: 8px;
}

.selected .agCheckbox  {
    border-color: #0091ea;
    // background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='%230091ea'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='m4.5 12.75 6 6 9-13.5' /%3E%3C/svg%3E%0A");
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%230091ea' d='M20.985 7.378L10.38 17.985l-6.364-6.364l1.414-1.414l4.95 4.95l9.192-9.193l1.414 1.414Z'/%3E%3C/svg%3E");
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat
}

.modal-container {
    background-color: rgba(255, 255, 255, 0.3);
}
